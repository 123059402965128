import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    $appStore,
    setCompanies,
    setCategories,
    setFilteredCompanies,
    setSortDirection,
    setSelectedDays,
} from './store';
import { GridCards } from './components/GridCards';
import { useStore } from 'effector-react';
import { mainFilterFunc, sortingData } from './libs/filterFuncs';
import { Checkbox } from './components/Checkbox';
import { CategoriesWrapper } from './components/CategoriesWrapper';
import { Search } from './components/Search';
import { eventDays } from './utils';

const App = () => {
    const [showFilters, setShowFilters] = useState(true);

    function toggleFilter() {
        setShowFilters(!showFilters);
    }
    const {
        companies,
        filteredCompanies,
        sortDirection,
        selectedDays,
        selectedCategories,
        selectedCompany,
    } = useStore($appStore);

    useEffect(() => {
        axios.get('https://yrityspaivat.com/companydata.js.php').then((r) => {
            setCompanies(sortingData(r.data.companies, sortDirection));
            setCategories(r.data.categories);
            setFilteredCompanies(sortingData(r.data.companies, sortDirection));
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (companies.length !== 0) {
            setFilteredCompanies(
                sortingData(
                    mainFilterFunc(
                        companies,
                        selectedDays,
                        selectedCategories,
                        selectedCompany
                    ),
                    sortDirection
                )
            );
        }
    }, [
        companies,
        selectedDays,
        sortDirection,
        selectedCategories,
        selectedCompany,
    ]);
    return (
        <div>
            <div className='row row-24 companies small-up-1 large-up-2'>
                <Search />
                {showFilters ? (
                    <div className='columns d-flex'>
                        <div className='card w-100'>
                            <div className='card-section d-md-flex align-items-center'>
                                <label className='label mb-2 mb-md-0 mr-4 mr-lg-2 mr-xl-5'>
                                    Päivämäärät
                                </label>
                                <div className='checkbox-holder'>
                                    {Object.values(eventDays).map((day) => {
                                        return (
                                            <Checkbox
                                                key={day.value}
                                                {...day}
                                                isChecked={
                                                    selectedDays.indexOf(
                                                        day.value
                                                    ) !== -1
                                                }
                                                onChange={(e) => {
                                                    if (!e.target.checked) {
                                                        setSelectedDays(
                                                            selectedDays.filter(
                                                                (d) =>
                                                                    d !==
                                                                    day.value
                                                            )
                                                        );
                                                    } else {
                                                        setSelectedDays([
                                                            ...selectedDays,
                                                            day.value,
                                                        ]);
                                                    }
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            {showFilters ? <CategoriesWrapper /> : null}

            <div className='row mb-5 d-md-none'>
                <div className='small-12'>
                    <div className='card text-center'>
                        <button
                            type='button'
                            className='btn btn-link d-flex justify-content-center align-items-center'
                            onClick={toggleFilter}
                        >
                            {showFilters ? 'Sulje' : 'Avaa'} lisävalinnat{' '}
                            <i className='icon-sort ml-3' />
                        </button>
                    </div>
                </div>
            </div>
            <div className='details-block mb-4'>
                <div className='info'>
                    Näytetään <b>{filteredCompanies.length}</b> yritystä
                </div>

                <div className='sorting'>
                    <label className='label mr-2 mr-md-4'>Järjestä</label>
                    <select
                        className='select select-filter js-select-filter'
                        onChange={(e) => {
                            setSortDirection(e.target.value);
                            setFilteredCompanies(
                                sortingData(filteredCompanies, e.target.value)
                            );
                        }}
                    >
                        <option value='a-z'>A-Z</option>
                        <option value='z-a'>Z-A</option>
                    </select>
                </div>
            </div>

            <GridCards />
        </div>
    );
};

export default App;
