import React from 'react';
import Modal from 'react-modal';

const typeOfLink = (type) => {
    switch (type) {
        case 'instagram':
            return <span className='fa fa-instagram' />;
        case 'youtube':
            return <span className='fa fa-youtube' />;
        case 'twitter':
            return <span className='fa fa-twitter' />;
        case 'linkedin':
            return <span className='fa fa-linkedin' />;
        case 'facebook':
            return <span className='fa fa-facebook' />;
    }
};

export const StyledModal = ({ company, isOpen, closeModal }) => {
    return (
        <Modal
            className='modal show modal-company modalReact'
            overlayClassName='modal-backdrop modalReact-backdrop'
            isOpen={!!isOpen}
            ariaHideApp={false}
            onRequestClose={closeModal}
        >
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <div className='close' onClick={closeModal}>
                            <span aria-hidden='true'>&times;</span>
                        </div>
                    </div>
                    <div className='modal-body'>
                        <div
                            className='img'
                            style={{
                                backgroundImage: `url(${company.logo})`,
                            }}
                        />
                        <h3 className='modal-title'>{company.name}</h3>
                
                        {company.show_videoconference_links ? (
                        <div className='btn-group mb-4'>
                            {company.videoconference.map(v => (
                                <a href={v.link} target='blank' rel="noopener noreferrer" className='btn btn-primary'>
                                    {v.text}
                                </a>
                            ))}
                        </div>
                        ) : null}

                        <div className='row'>
                            <div className='small-12'>
                                <div className='row mb-4'>
                                    <div className='columns small-12 medium-6'>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: company.infotext,
                                            }}
                                        />
                                    </div>
                                    <div className='columns small-12  medium-6 px-md-5'>
                                        {company.schedule ? (
                                            <>
                                            <h4 className='h5'>{company.infotext.toLowerCase().includes('general info') ? 'Schedule' : 'Aikataulu'}</h4>
                                            <div className='company-schedule' dangerouslySetInnerHTML={{
                                                    __html: company.schedule,
                                                }}></div>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='modal-footer'>
                        {company.some.length > 0 ? (
                            <ul className='list-socials'>
                                {company.some.map((some) => 
                                    <li>
                                        <a target='_blank' rel='noopener noreferrer' href={some.link}>
                                            {typeOfLink(some.type)}
                                        </a>
                                    </li>
                                )}
                            </ul>
                        ) : null}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
