import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { $appStore, setSelectedCompany } from '../../store';

export const Search = () => {
    const [inputValue, setInputValue] = useState('');
    const { companies } = useStore($appStore);

    function inputHandle(e) {
        setInputValue(e.target.value);
    }
    function searchHandle() {
        let val = inputValue.toLowerCase();
        const filteredData = companies.filter((company) =>
            company.name.toLowerCase().includes(val)
        );
        val.length === 0
            ? setSelectedCompany(companies)
            : setSelectedCompany(filteredData);
    }
    function clearForm() {
        setInputValue('');
        setSelectedCompany(null);
    }

    // useEffect(() => {
    //     if (companies.length > 0) {
    //         setSearchCompany(
    //             companies.map((company) => {
    //                 return {
    //                     value: company.name,
    //                     label: company.name,
    //                 };
    //             })
    //         );
    //     }
    // }, [companies]);

    return (
        <div className='columns d-flex'>
            <form className='card w-100 search-block' onSubmit={(e) => {
                        e.preventDefault();
                        searchHandle();
                    }}>
                <div className='card-section d-md-flex align-items-center'>
                    <label className='label mr-5 mr-lg-2 mr-xl-5 order-md-1'>
                        Yrityshaku
                    </label>

                    <button
                        className='btn btn-reset btn-secondary ml-2 order-md-3'
                        type='reset'
                        onClick={clearForm}
                    >
                        Tyhjennä
                    </button>
                    <div className='input-group mb-0 order-md-2'>
                        <input
                            className='form-control'
                            value={inputValue}
                            onChange={inputHandle}
                            placeholder='Hae nimellä'
                        />
                        <div className='input-group-button'>
                            <button
                                type='submit'
                                className='btn red'
                                onClick={searchHandle}
                            >
                                Hae
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    );
};
