const longDateFormat = { weekday: 'short', day: '2-digit', month: '2-digit' };
const shortDateFormat = { day: '2-digit', month: '2-digit' };

export const eventDays = {
    day1: {
        value: 'day1',
        title: window.ypEventDay1Date
            ? new Intl.DateTimeFormat('fi', longDateFormat).format(
                  window.ypEventDay1Date
              )
            : 'Keskiviikko 2.2.',
        label: window.ypEventDay1Date
            ? new Intl.DateTimeFormat('fi', shortDateFormat).format(
                  window.ypEventDay1Date
              )
            : '2.2.',
    },
    day2: {
        value: 'day2',
        title: window.ypEventDay2Date
            ? new Intl.DateTimeFormat('fi', longDateFormat).format(
                  window.ypEventDay2Date
              )
            : 'Torstai 3.2.',
        label: window.ypEventDay2Date
            ? new Intl.DateTimeFormat('fi', shortDateFormat).format(
                  window.ypEventDay2Date
              )
            : '3.2.',
    },
};
