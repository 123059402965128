const uniqBy = require('lodash.uniqby');
const intersection = require('lodash.intersection');

export function sortingData(data, direction) {
    switch (direction) {
        case 'a-z':
            return data.sort((a, b) =>
                a.name.replace(' ', '').localeCompare(b.name.replace(' ', ''))
            );
        case 'z-a':
            return data
                .sort((a, b) =>
                    a.name
                        .replace(' ', '')
                        .localeCompare(b.name.replace(' ', ''))
                )
                .reverse();
        default:
            return;
    }
}
// export const filterByDays = (data, days) => {
//     const newDays = {};
//     days.map((day) => {
//         return (newDays[day] = true);
//     });
//     return uniqBy(filter(data, newDays), 'name');
// };
export const filterByDays = (data, days) => {
    const newData = [];
    data.map((company) => {
        days.map((day) => {
            if (company[day] === true) {
                newData.push(company);
            }
        });
    });
    return uniqBy(newData, 'id');
};

export const filterByCategory = (data, categories) => {
    const newArr = [];

    data.forEach((company) => {
        if (intersection(company.categories, categories).length > 0) {
            newArr.push(company);
        }
    });

    return uniqBy(newArr, 'id');
};

export const mainFilterFunc = (data, days, categories, selectedCompany) => {
    if (selectedCompany) {
        if (days.length > 0) {
            if (categories.length > 0) {
                return filterByDays(
                    filterByCategory(selectedCompany, categories),
                    days
                );
            } else {
                return filterByDays(selectedCompany, days);
            }
        } else {
            if (categories.length > 0) {
                return filterByCategory(selectedCompany, categories);
            } else {
                return selectedCompany;
            }
        }
    } else if (days.length > 0) {
        if (categories.length > 0) {
            return filterByDays(filterByCategory(data, categories), days);
        } else {
            return filterByDays(data, days);
        }
    } else {
        if (categories.length > 0) {
            return filterByCategory(data, categories);
        } else {
            return data;
        }
    }
};
