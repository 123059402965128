import React, { useEffect, useState } from 'react';
import { Card } from '../Card';
import { useStore } from 'effector-react';
import { $appStore } from '../../store';

export const GridCards = () => {
    const { filteredCompanies, companies: allCompanies } = useStore($appStore);
    const [addMore, setAddMore] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [activeOffset, setActiveOffset] = useState(0);
    useEffect(() => {
        setCompanies(filteredCompanies.slice(0, 19));
        setActiveOffset(19);
    }, [filteredCompanies]);

    useEffect(() => {
        if (
            companies.length +
                filteredCompanies.slice(activeOffset, setActiveOffset + 19)
                    .length ===
            filteredCompanies.length
        ) {
            setAddMore(false);
        } else {
            setAddMore(true);
        }
    }, [companies, filteredCompanies]);
    function addMoreCompanies(offset) {
        setCompanies([
            ...companies,
            ...filteredCompanies.slice(offset, offset + 19),
        ]);
        setActiveOffset(offset + 19);
    }

    return (
        <div className='row row-24 small-up-1 medium-up-3 large-up-6 mb-5'>
            {allCompanies.length > 0 &&
                allCompanies.filter(c => c.sponsored).map((company) => {
                    return <div style={{display: 'none'}}><Card {...company} key={company.name} /></div>;
                })}
            {companies.length > 0 &&
                companies.map((company) => {
                    return <Card {...company} key={company.name} />;
                })}
            {addMore && (
                <div className='columns d-flex align-items-center justify-content-center'>
                    <button
                        className='btn btn-dark btn-more mx-2'
                        onClick={addMoreCompanies.bind(this, activeOffset)}
                    >
                        Näytä lisää
                    </button>
                </div>
            )}
        </div>
    );
};
