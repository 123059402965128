import React from 'react';

export const Checkbox = (props) => {
    const { value, onChange, title, isChecked } = props;

    return (
        <div className='custom-control custom-checkbox'>
            <input
                value={value}
                checked={isChecked}
                type='checkbox'
                className='custom-control-input'
                id={value}
                onChange={onChange}
            />
            <label className='custom-control-label' htmlFor={value}>
                {title}
            </label>
        </div>
    );
};
