import { createStore, createApi } from 'effector';

const $appStore = createStore({
    companies: [],
    categories: [],
    filteredCompanies: [],
    sortDirection: 'a-z',
    selectedDays: [],
    selectedCategories: [],
    selectedCompany: null,
});
const {
    setCompanies,
    setCategories,
    setFilteredCompanies,
    setSortDirection,
    setSelectedDays,
    setSelectedCategories,
    selectAllCategories,
    clearAllSelectedCategories,
    setSelectedCompany,
} = createApi($appStore, {
    setSelectedCompany: (store, payload) => {
        return { ...store, selectedCompany: payload };
    },
    setCompanies: (store, payload) => {
        return { ...store, companies: payload };
    },
    setCategories: (store, payload) => {
        return { ...store, categories: payload };
    },
    setFilteredCompanies: (store, payload) => {
        return { ...store, filteredCompanies: [...payload] };
    },
    setSortDirection: (store, payload) => {
        return { ...store, sortDirection: payload };
    },
    setSelectedDays: (store, payload) => {
        return { ...store, selectedDays: payload };
    },
    setSelectedCategories: (store, payload) => {
        return { ...store, selectedCategories: payload };
    },
    selectAllCategories: (store) => {
        return {
            ...store,
            selectedCategories: store.categories.map((cat) => cat.id),
        };
    },
    clearAllSelectedCategories: (store) => {
        return { ...store, selectedCategories: [] };
    },
});
export {
    $appStore,
    setCompanies,
    setCategories,
    setFilteredCompanies,
    setSortDirection,
    setSelectedDays,
    setSelectedCategories,
    selectAllCategories,
    clearAllSelectedCategories,
    setSelectedCompany,
};
