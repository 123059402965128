import React from 'react';
import Select from 'react-select';

// import { Checkbox } from '../Checkbox';
import { useStore } from 'effector-react';
import {
    $appStore,
    clearAllSelectedCategories,
    selectAllCategories,
    setSelectedCategories,
} from '../../store';

export const CategoriesWrapper = () => {
    const { categories, selectedCategories } = useStore($appStore);

    const options = categories.map((cat) => ({value: cat.id, label: cat.name}));

    const setSelectedOption = (e, props) => {
        console.log(props.action);
        if(props.action === 'select-option') {
            setSelectedCategories([
                ...selectedCategories,
                props.option.value,
            ]);
        } else if(props.action === 'remove-value') {
            setSelectedCategories(
                selectedCategories.filter(
                    (d) => d !== props.removedValue.value
                )
            );
        }  else if(props.action === 'clear') {
            clearAllSelectedCategories();
         }
        
    }


    return (
        <div className='row mb-5'>
            <div className='small-12 columns'>
                <div className='card category-filter'>
                    <div className='card-section d-lg-flex align-items-center py-2'>
                        <h4 className='card-title mb-2 mb-lg-0 mr-4 mr-lg-2 mr-xl-4'>
                            Koulutusalat
                        </h4>

                        <div className='checkbox-holder flex-grow-1 mb-1 mb-lg-0'>

                            <Select placeholder="Valitse koulutusalat" classNamePrefix="autocomplete" className="w-100 categories-autocomplete" isMulti={true} isSearchable={false} options={options} onChange={setSelectedOption} />

                            {/* {categories.map((cat) => {
                                return (
                                    <Checkbox
                                        title={cat.name}
                                        value={cat.id}
                                        key={cat.id}
                                        isChecked={
                                            selectedCategories.indexOf(
                                                cat.id
                                            ) !== -1
                                        }
                                        onChange={(e) => {
                  
                                        }}
                                    />
                                );
                            })} */}
                        </div>
{/* 
                        <div className='btn-group ml-5'>
                            <button
                                className='btn btn-light btn-sm'
                                onClick={selectAllCategories}
                            >
                                Valitse kaikki
                            </button>
                            <button
                                className='btn btn-light btn-sm'
                                onClick={clearAllSelectedCategories}
                            >
                                Poista valinnat
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
