import React, { useState } from 'react';
import { StyledModal } from '../Modal';
import { eventDays } from '../../utils';


export const Card = (props) => {
    const { name, logo, day1, day2, id, sponsored } = props;
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = (id) => {
        const modal = document.getElementById(`company-modal-${id}`);
        if (modal) {
            modal.classList.add('show');
            const bgdrop = document.getElementById('modal-backdrop');
            if (bgdrop) {
                bgdrop.classList.remove('fade');
            }
        } else {
            setOpenModal(true);
        }
    };

    return (
        <div className='columns d-flex'>
            <div
                id={`company-card-${id}`}
                className={`card card-company ${sponsored ? 'sponsored' : ''}`}
                onClick={() => handleOpenModal(id)}
            >
                <div className='card-section'>
                    <div className='d-flex align-items-center'>
                        <div className='img'>
                            <img src={logo} alt='logo' />
                        </div>

                        <div className='d-block d-md-none'>
                            <div className='time'>
                                <i className='icon-calendar' />
                                {day1 && day2
                                    ? `${eventDays.day1.label} – ${eventDays.day2.label}`
                                    : day1
                                    ? eventDays.day1.label
                                    : day2
                                    ? eventDays.day2.label
                                    : ''}
                            </div>
                            {/* <div
                                className='more'
                            >
                                Lisätiedot
                            </div> */}
                        </div>
                    </div>
                    <div className='card-body'>
                        <h4 className='card-title'>{name}</h4>

                        <div className='d-none d-md-block'>
                            <div className='time'>
                                <i className='icon-calendar' />
                                {day1 && day2
                                    ? `${eventDays.day1.label} – ${eventDays.day2.label}`
                                    : day1
                                    ? eventDays.day1.label
                                    : day2
                                    ? eventDays.day2.label
                                    : ''}
                            </div>
                            {/* <div
                                className='more'
                            >
                                Lisätiedot
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <StyledModal
                company={props}
                isOpen={openModal}
                closeModal={() => setOpenModal(false)}
            />
        </div>
    );
};
